import { useNavigate } from 'react-router-dom';
import { Sidebar as HzSidebar } from '@squantumengine/horizon';
import type { SidebarItemProps } from '@squantumengine/horizon';
import { useMasterStore } from 'common/stores/master';
import { ALL_ROUTE_PATH_MAPPING } from './dashboard.constants';

const Sidebar = () => {
	const navigate = useNavigate();
	const selectedMenu = useMasterStore((state) => state.selectedMenu);
	const navigationMenu = useMasterStore((state) => state.navigationMenu);

	const goToPath = (item: SidebarItemProps) => {
		const path = ALL_ROUTE_PATH_MAPPING[item.id];
		path && navigate(path);
	};

	return (
		<HzSidebar
			items={navigationMenu}
			selectedMenu={selectedMenu}
			width={256}
			onClick={goToPath}
			selectedClassName="hz-bg-blue-50 hz-text-blue-500"
			hoveredClassName="hz-bg-blue-50 hover:hz-text-blue-500"
		/>
	);
};

export default Sidebar;
